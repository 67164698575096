<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Search -->
        <!-- <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col> -->

        <!-- Entry -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-transactions-cashbank-request-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.transactionsCashbank.singular.create') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-transactions-cashbank-request-detail', params: { id: item.id}}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            #{{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Account Type -->
      <template #cell(remark)="{ item }">
        <small class="font-italic">
          {{ item.remark }} {{ $t('globalSingular.via') }}
          <span class="font-weight-bolder text-primary">
            {{ item.office ? item.office.label : '' }}
          </span>
        </small>
      </template>

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.amount > 0) ? 'text-success' : 'text-danger' ]">
          {{
            formatCurrency(item.amount)
          }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="{ item }">
        <b-badge pill :variant="item.is_approved === true ? 'light-success' : 'light-warning'">
          {{ item.is_approved === true ? 'Approved' : 'Need Confirmation' }}
        </b-badge>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import { formatCurrency } from '@/utils/formatter'
// import useListTable from '@/comp-functions/useListTable'
import useListTablePage from '@/comp-functions/useListTablePage';
import { ref } from '@vue/composition-api';

// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge,
    TableSearchColumn
  },


  setup () {
    const { $t } = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    } = useListTablePage({ url: "transaction/cashbank/request?" })

    // Table Handlers
    const tableColumns = ref([
      {
        key: 'code',
        label: 'Code',
        sortable: true,
        search: { type: 'text', value: '' }
      },
      {
        key: 'date',
        label: $t('globalSingular.date'),
        sortable: true ,
        search: { type: 'date', value: '' }
      },
      {
        key: 'office.brand',
        label: $t('globalSingular.branch'),
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'amount',
        label: $t('globalSingular.amount'),
        thClass: 'text-right',
        tdClass: 'text-right',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'remark',
        label: 'Description',
        search: { type: 'text', value: '' }
      },
      {
        key: 'status',
        label: $t('globalSingular.status'),
        search: {
          type: 'select',
          value: '',
          key: 'is_approved',
          options: [
            { id: true, label: 'Approved' },
            { id: false, label: 'Need Confirmation' },
          ]
        }
      },
    ])

    return {
      formatCurrency,
      tableColumns,
      searchData,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage
    }
  },


  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    }
  }
}
</script>
